import React from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";

import PublicRoute from "containers/PublicRoute/PublicRoute";

import Home from "./containers/Home/Home";

const hist = createBrowserHistory();

const router = (
  <Router history={hist} onUpdate={() => window.scrollTo(0, 0)}>
    <Switch>
      <PublicRoute exact path="/*" component={Home} />
    </Switch>
  </Router>
);

export { router };
