var APP_API_URL = process.env.REACT_APP_API_URL;

var jwt_decode = require('jwt-decode');

export const setCredentials = async (token,refresh) => {
  localStorage.setItem("userToken", token);
  localStorage.setItem("refreshToken", refresh);
};

export const getCredentials = async () => {
  try {

    let userToken = localStorage.getItem("userToken");
    let refreshToken = localStorage.getItem("refreshToken");

    if (userToken && refreshToken) {
      let nToken = await getVerifiedKeys(userToken, refreshToken);

      if (userToken != null && nToken != null) {
        return nToken;
      } else {
        return null;
      }
    } else {
      return null;
    }
  } catch (e) {
    console.log(e);
  }
  return null;
};

function isTokenExpired(token) {
  var decoded = jwt_decode(token);

  if (decoded.exp < Date.now() / 1000) {
    return true
  } else {
    return false
  }
}

async function getAccessUsingRefresh (refreshToken) {
  return fetch(APP_API_URL+'/api/auth/refresh', {
    method: 'POST',
    headers: {
      //'Content-Type': 'application/json',
      authorization: `Bearer ${refreshToken}`,
    },
  }).then(res => res.json())
}

async function getVerifiedKeys(token, refresh) {
  console.log('Loading keys from storage')

  if (token) {
    console.log('checking access')

    if (!isTokenExpired(token)) {
      console.log('returning access')

      return token
    } else {
      console.log('access expired')

      console.log('checking refresh expiry')

      if (!isTokenExpired(refresh)) {
        console.log('fetching access using refresh-',refresh)

        const response = await getAccessUsingRefresh(refresh)

        await setCredentials(response.login.token, response.login.refresh);

        console.log('UPDATED ONE')

        return response.login.token
      } else {
        console.log('refresh expired, please login')

        return null
      }
    }
  } else {
    console.log('access not available please login')

    return null
  }
}
