import React from "react";
import { connect } from "react-redux";
import moment from "moment-timezone";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Container,
  Col,
  Row,
  Badge,
} from "reactstrap";

import NotificationAlert from "react-notification-alert";
import { load } from "reducers/QrCode";
import { ping } from "reducers/Documents";
import { Redirect } from "react-router-dom";
import Validation from "containers/Documents/Validador";

class Home extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  async componentDidMount() {
    //await this.props.ping();
    this.props.load(this.props.location.pathname.substr(1));
  }

  renderParts() {
    return this.props.data.parts.map((part, key) => {
      return (
        <li key={key}>
          <div className="timeline-badge info">
            <i className="nc-icon nc-touch-id" />
          </div>
          <div className="timeline-panel">
            <div className="timeline-heading">
              <Badge color="info" pill>
                Assinatura Digital
              </Badge>
            </div>
            <div className="timeline-body">
              <p>{part.name}</p>
              <p>
                CPF:{" "}
                {part.cpf
                  .replace(/(.{4}).{6}/, "$1***.***")
                  .replace(/(.{10}).{2}/, "$1**")}
              </p>
              <p>Data: {moment(part.signDate).tz('UTC').format("DD/MM/YYYY HH:mm:ss")}</p>
              <p>IP: {part.ip}</p>
              <p>Assinatura: {part.hash}</p>

              {part.latLong && (
                <>
                  <p>Latitude/Longitude: {part.latLong}</p>
                  <p>Cidade - UF: {part.latLongCidadeUF}</p>
                </>
              )}

              <hr />
            </div>
          </div>
        </li>
      );
    });
  }

  render() {
    if (!this.props.data) {
      return <Validation />;
    }
    return (
      <div className="login-page">
        <NotificationAlert ref="notificationAlert" />
        <Container>
          <Row>
            <Col className="ml-auto mr-auto mb-5 mt-5" lg="4" md="6">
              <div className="col-md-8 ml-auto mr-auto">
                <img
                  alt="..."
                  src={require("stylesheets/img/logo/confia_site_branca.png")}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="ml-auto mr-auto" md="12">
              <Card className="card-login">
                <CardHeader>
                  <h4 className="header text-center">
                    Documento com segurança jurídica
                  </h4>
                </CardHeader>
                <CardBody>
                  <Col md="12">
                    <Card className="card-timeline card-plain">
                      <CardBody>
                        <ul className="timeline">
                          <li className="timeline-inverted">
                            <div className="timeline-badge danger">
                              <i className="nc-icon nc-single-copy-04" />
                            </div>
                            <div className="timeline-panel">
                              <div className="timeline-heading">
                                <Badge color="danger" pill>
                                  Upload do Documento
                                </Badge>
                              </div>
                              <div className="timeline-body">
                                <p>Remetente: {this.props.data.senderName}</p>
                                {this.props.data.cnpj !=
                                  "22.222.222/2222-22" && (
                                  <p>CNPJ: {this.props.data.cnpj}</p>
                                )}

                                <p>Tipo: {this.props.data.type}</p>
                                <p>Documento: {this.props.data.name}</p>
                                <p>Código do Selo: {this.props.data.hash}</p>
                              </div>
                              <h6>
                                <i className="fa fa-clock-o" />
                                {moment(
                                  this.props.data.registrationDate
                                ).tz('UTC').format("DD/MM/YYYY HH:mm:ss")}
                              </h6>
                            </div>
                          </li>
                          {this.renderParts()}
                          <li className="timeline-inverted">
                            <div className="timeline-badge warning">
                              <i className="nc-icon nc-check-2" />
                            </div>
                            <div className="timeline-panel">
                              <div className="timeline-heading">
                                <Badge color="warning" pill>
                                  Assinatura Digital Confia
                                </Badge>
                              </div>
                              <div className="timeline-body">
                                <p>
                                  Data:{" "}
                                  {moment(
                                    this.props.data.registrationDateRegistry
                                  ).tz('UTC').format("DD/MM/YYYY HH:mm:ss")}
                                </p>
                                <p>Hash: {this.props.data.signHash}</p>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="timeline-badge success">
                              <i className="nc-icon nc-bank" />
                            </div>
                            <div className="timeline-panel">
                              <div className="timeline-heading">
                                <Badge color="success" pill>
                                  Cartório
                                </Badge>{" "}
                                -{" "}
                                {this.props.data.status === 2
                                  ? "Registro Concluído"
                                  : "Aguardando Registro"}
                              </div>
                              {
                                <div className="timeline-body">
                                  <p>
                                    Central IRTDPJ Brasil{" "}
                                    {this.props.data.protocol &&
                                      "- Protocolo: " +
                                        this.props.data.protocol}
                                  </p>
                                  {this.props.data.registryName && (
                                    <p>
                                      Cartório: {this.props.data.registryName}
                                    </p>
                                  )}
                                  {this.props.data.status === 2 && (
                                    <p>
                                      <Button
                                        className="btn-round"
                                        color="success"
                                        outline
                                        href={this.props.data.rtdpjFileUrl}
                                      >
                                        <i className="fa fa-barcode" />
                                        Comprovante de Registro
                                      </Button>
                                    </p>
                                  )}
                                  <hr />
                                </div>
                              }
                            </div>
                          </li>
                        </ul>
                      </CardBody>
                    </Card>
                  </Col>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <div
          className="full-page-background"
          style={{
            backgroundImage: `url(${require("stylesheets/img/bg/bgEmpresas.jpeg")})`,
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state.QrCode,
});

function mapDispatchToProps(dispatch) {
  return {
    load: (hash) => dispatch(load(hash)),
    ping: () => dispatch(ping()),
  }; // here we're mapping actions to props
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
