import { client } from "store";

const VALIDATION = "confia/validation/VALIDATION";
const VALIDATION_SUCCESS = "confia/validation/VALIDATION_SUCCESS";
const VALIDATION_FAIL = "confia/validation/VALIDATION_FAIL";
const PING = "confia/auth/PING";

export function ping() {
  client.defaults.baseURL = process.env.REACT_APP_API_URL_2;
  return {
    type: PING,
    payload: {
      request: {
        method: "get",
        url: `/api/status`,
      },
    },
  };
}

export function checkDocument(file) {
  client.defaults.baseURL = process.env.REACT_APP_API_URL_2;
  return {
    type: VALIDATION,
    payload: {
      request: {
        method: "post",
        url: `/api/documents/checkDocument`,
        data: file,
      },
    },
  };
}

const INITIAL_STATE = { verification: null, success: false };

export default function Documents(state = INITIAL_STATE, action) {
  switch (action.type) {
    case VALIDATION:
      return {
        ...state,
        loading: true,
      };
    case VALIDATION_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        status: action.payload.status,
        verification: action.payload.data,
      };
    case VALIDATION_FAIL:
      return {
        ...state,
        loading: false,
        errorMessage: "Não foi possível recuperar as informações do usuário.",
        error: true,
      };

    // initial state
    default:
      return state;
  }
}
