import { client } from "store";

const UNLOAD = "confia/qrcode/UNLOAD";
const LOAD = "confia/qrcode/LOAD";
const LOAD_SUCCESS = "confia/qrcode/LOAD_SUCCESS";
const LOAD_FAIL = "confia/qrcode/LOAD_FAIL";

export function load(id) {
  client.defaults.baseURL = process.env.REACT_APP_API_URL;
  return {
    type: LOAD,
    payload: {
      request: {
        method: "get",
        url: `/api/qrcode/${id}`,
      },
    },
  };
}

export function unload() {
  return {
    type: UNLOAD,
  };
}

const INITIAL_STATE = { data: null };

export default function QrCode(state = INITIAL_STATE, action) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        loading: true,
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data.code,
      };
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        errorMessage: "Não foi possível recuperar as informações do usuário.",
        error: true,
      };

    case UNLOAD:
      return {
        state: INITIAL_STATE,
      };

    // initial state
    default:
      return state;
  }
}
