import React from 'react';
import { Container, Row, Col, Image, Spinner, Button } from 'react-bootstrap';
import ShowInformation from './ShowInformation';
import { connect } from 'react-redux';
import { checkDocument } from 'reducers/Documents';
import { unload } from 'reducers/QrCode';
import './styles.scss';
class Validation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      showInformation: false,
      inputError: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.fileInput = React.createRef();
  }

  componentDidMount() {
    this.props.unload();
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    if (
      !this.fileInput.current.files[0] ||
      this.fileInput.current.files[0].type !== "application/pdf"
    ) {
      this.setState({ inputError: true });
      return;
    }

    const arquivo = await this.documentToBase64(
      this.fileInput.current.files[0]
    );
    const file = {
      nomeArquivo: this.fileInput.current.files[0].name,
      dadosArquivo: arquivo.split(',')[1],
    };
    this.setState({ loading: true });
    await this.props.checkDocument(file);
    this.setState({ loading: false });
    if (this.props.verification) {
      this.setState({ showInformation: true });
    }
  };

  async documentToBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        resolve(reader.result);
      };

      reader.onerror = reject;

      reader.readAsDataURL(file);
    });
  }

  render() {
    return (
      <>
        <Container>
          <Row className='mt-4'>
            <Col>
              <Row className='align-items-center'>
                <img src={require('../../stylesheets/img/logo/logo.svg')} />
                <p className='title-page'>| Validador de documentos </p>
              </Row>
            </Col>
          </Row>
          <Row className='mt-3'>
            <Col>
              <p className='text-tips'>
                {' '}
                Aqui você pode aferir se um arquivo assinado na
                <strong> plataforma Confia </strong>está válido, verificando sua
                integridade autenticidade e validade de seus certificados
                digitais.{' '}
              </p>
            </Col>
          </Row>
          <form onSubmit={this.handleSubmit}>
            <Row className='mb-3 border-bottom mt-2'>
              <Col md='7' sm='7' className='align-items-center'>
                <input
                  type='file'
                  ref={this.fileInput}
                  accept='.pdf'
                  onChange={() =>
                    this.setState({
                      showInformation: false,
                      loading: false,
                      inputError: false,
                    })
                  }
                />
                {this.state.loading ? (
                  <Button className='btn btn-primary' disabled>
                    <Spinner
                      as='span'
                      animation='border'
                      size='sm'
                      role='status'
                      aria-hidden='true'
                    />
                    <span className='visually-hidden ml-1'>Carregando...</span>
                  </Button>
                ) : (
                  <Button className='btn btn-primary' type='submit' size=''>
                    VERIFICAR
                  </Button>
                )}
              </Col>
              {this.state.inputError && (
                <Col sm='12'>
                  <p className='error-message'>
                    ** Adicione um documento válido para continuar. **{' '}
                  </p>
                </Col>
              )}
            </Row>
          </form>
          {this.state.showInformation && (
            <>
              {this.props.success ? (
                <>
                  {' '}
                  <ShowInformation {...this.props.verification} />{' '}
                </>
              ) : (
                <p> Erro ao processar a requisição </p>
              )}
            </>
          )}
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state.Documents,
});

function mapDispatchToProps(dispatch) {
  return {
    checkDocument: (file) => dispatch(checkDocument(file)),
    unload: () => dispatch(unload()),
  }; // here we're mapping actions to props
}
export default connect(mapStateToProps, mapDispatchToProps)(Validation);
