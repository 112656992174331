import React from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import { ImCheckmark, ImCross } from 'react-icons/im';
import ReactTable from 'react-table';
import './styles.scss';
import moment from 'moment';

class ShowInformation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }
  render() {
    return (
      <>
        <Row className='mt-1 mb-2'>
          <Col md='5'>
            <p className='subTitle'> Validador do Documento </p>
            <p>
              Documento: <strong>{this.props.documento ?? ''}</strong>
            </p>

            <p>
              Identificador: <strong>{this.props.identificador ?? ''} </strong>
            </p>
            <p>
              Data e Hora:{' '}
              <strong>
                {this.props.dataHora
                  ? moment(this.props.dataHora).format('DD/MM/YYYY HH:mm:ss')
                  : ''}
              </strong>
            </p>
            <p>
              Integridade do documento:{' '}
              {this.props.integridade ? (
                <ImCheckmark color='green' />
              ) : (
                <ImCross color='red' />
              )}
            </p>
            <p>
              Autenticidade das assinaturas:{' '}
              {this.props.assinadoPorConfia ? (
                <ImCheckmark color='green' />
              ) : (
                <ImCross color='red' />
              )}
            </p>
            <p>
              Identificação dos signatários:{' '}
              {this.props.identificacaoSignatarios ? (
                <ImCheckmark color='green' />
              ) : (
                <ImCross color='red' />
              )}
            </p>
          </Col>
          <Col>
            <Card className=''>
              <Card.Header className='border-bottom'>
                <p className='subTitle'> Assinaturas </p>
              </Card.Header>
              <Card.Body>
                <ReactTable
                  data={this.props.assinaturas}
                  columns={columns}
                  showPagination={false}
                  minRows={2}
                  resizable={false}
                  noDataText={'O documento não possui assinaturas'}
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}
export default ShowInformation;

const columns = [
  {
    headerClassName: 'text-center',
    Header: 'CPF',
    accessor: 'signerDoc',
    Cell: (row) =>
      row.original.signerDoc === '34119697000136' ? (
        <div>"ICP-Brasil Confia"</div>
      ) : (
        row.original.signerDoc
      ),
  },
  {
    headerClassName: 'text-center',
    Header: 'Status',
    accessor: 'validConfiaCertificate',
    Cell: (row) =>
      row.original.validConfiaCertificate ? (
        <ImCheckmark color='green' />
      ) : (
        <ImCross color='red' />
      ),
  },
  {
    headerClassName: 'text-center',
    Header: 'Data e hora',
    accessor: 'signatureDate',
    Cell: (row) => {
      return moment(row.original.signatureDate).format('DD/MM/YYYY HH:mm:ss');
    },
  },
];
